import React from 'react'
import { SiteHeader as Component } from '@swp/components'
import items from './ItemSets'

const languages = []

export default function SiteHeader({ set = '', currentLanguage }) {
return (
  <Component className="--spacing4 --size-M" type={"SiteHeader"} fixed={false} layout={"1"} social={{"twitter":"","facebook":"","instagram":""}} logo={{"src":"https://cdn.swbpg.com/o/1438/32bb989063aa49ecbc85189bd6202010.png"}} logoMobile={{"src":"https://cdn.swbpg.com/o/1438/32bb989063aa49ecbc85189bd6202010.png"}} title={{"content":"tel. 777 083 538  půjčovna","className":""}} button={{"content":"objednávka","backgroundColor":"rgba(104,79,226,1)","use":"page","url":"tel:+420777083538","page":"/","section":"jxavfellas","className":"btn-box btn-box--hvr2 btn-box--pbtn5 btn-box--shape3 btn-box--shadow3 fs--18"}} item={{"className":""}} items={items?.[set]?.items} languages={languages} currentLanguage={currentLanguage} />
)
}